import React from 'react';
import { ShoppingCartIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ShoppingCart';
import { VenueIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Venue';
import { PaperInvitationsIcon } from '@zola/zola-ui/src/components/SvgIconsV3/PaperInvitations';
import { UsersIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Users';
import { MapPinIcon } from '@zola/zola-ui/src/components/SvgIconsV3/MapPin';

import insertInArrayIf from '@zola-helpers/client/dist/es/util/insertInArrayIf';
import {
  UniversalSearchSuggestionCategories,
  UniversalSearchCategorySuggestionsView,
  UniversalSearchSuggestionView,
} from '../types';

const FEATURE_SUGGESTION_LIMIT = 1;
const PRIMARY_CATEGORY_SUGGESTION_LMIT = 11;
const TOTAL_SUGGESTION_LIMIT = 14;

const getCategorySrpContent = ({
  isBaby,
  isGuest,
}: {
  isBaby: boolean;
  isGuest: boolean;
}): Partial<
  Record<
    UniversalSearchSuggestionCategories | 'LOCATIONS',
    {
      term: string;
      icon: JSX.Element;
      destinationUrl: string;
      isSubCategorySrp?: boolean;
    }
  >
> => ({
  SHOP: {
    term: 'Shop',
    icon: <ShoppingCartIcon height={20} width={20} />,
    destinationUrl: '/search/gifts?q=',
  },
  MARKETPLACE: {
    term: 'Vendors',
    icon: <VenueIcon height={20} width={20} />,
    destinationUrl: '/wedding-vendors',
    isSubCategorySrp: true,
  },
  PAPER: {
    term: 'Invites and paper',
    icon: <PaperInvitationsIcon height={20} width={20} />,
    destinationUrl: isGuest ? '/wedding-planning/paper' : '/wedding-planning/universal?q=',
  },
  COUPLE: {
    term: isBaby ? 'Registries' : 'Couples',
    icon: <UsersIcon height={20} width={20} />,
    destinationUrl: `/search/${isBaby ? 'baby' : 'wedding'}-registry?query=`,
  },
  LOCATIONS: {
    term: 'Locations',
    icon: <MapPinIcon height={20} width={20} />,
    destinationUrl: '/wedding-vendors',
  },
});

const getCategoryMappedFromEnum = (
  categoryEnum: UniversalSearchSuggestionCategories,
  categorySuggestions: UniversalSearchCategorySuggestionsView[]
) => categorySuggestions.find(({ category }) => category === categoryEnum);

const mapCategoriesToSuggestionResults = ({
  categorySuggestions = [],
  isBaby,
  isGuest,
  searchTerm,
  sortingPriority,
}: {
  categorySuggestions: UniversalSearchCategorySuggestionsView[] | undefined;
  isBaby: boolean;
  isGuest: boolean;
  searchTerm: string;
  sortingPriority: UniversalSearchSuggestionCategories[];
}): UniversalSearchCategorySuggestionsView[] => {
  const noResultsAnyCategory = categorySuggestions.every((cat) => !cat.suggestions.length);

  // Each category has a base SRP link; exception: FEATURE
  const buildSrpResult = (
    currentCategoryEnum: UniversalSearchSuggestionCategories | 'LOCATIONS'
  ): UniversalSearchSuggestionView => {
    const { term, destinationUrl, icon, isSubCategorySrp } =
      getCategorySrpContent({ isBaby, isGuest })[currentCategoryEnum] || {};
    return {
      type: '',
      secondary_text: '',
      term: `${searchTerm} in ${term}`,
      destination_url: destinationUrl
        ? `${destinationUrl}${destinationUrl.includes('?') ? encodeURI(searchTerm) : ''}`
        : '',
      isSRP: true,
      isSubCategorySrp,
      icon,
      parentCategory: currentCategoryEnum === 'LOCATIONS' ? 'MARKETPLACE' : currentCategoryEnum,
    };
  };

  const sortedCategorySuggestions: UniversalSearchCategorySuggestionsView[] = sortingPriority
    .filter(
      (categoryEnum) =>
        categoryEnum !== 'FEATURE' ||
        // Only include FEATURE if !!suggestions.length; no base SRP
        (categoryEnum === 'FEATURE' &&
          getCategoryMappedFromEnum(categoryEnum, categorySuggestions)?.suggestions.length)
    )
    .map((categoryEnum) => {
      // Does this Category exist in the API results? Will eventually always be true, but Paper is still a WIP on BE, so it is currently omitted
      const categoryMappedFromEnum = getCategoryMappedFromEnum(categoryEnum, categorySuggestions);
      const suggestions = categoryMappedFromEnum?.suggestions.length
        ? [
            // Don't include an SRP link for Zola Features category
            ...insertInArrayIf(
              categoryMappedFromEnum.category !== 'FEATURE',
              buildSrpResult(categoryEnum)
            ),
            ...categoryMappedFromEnum?.suggestions,
          ]
        : [buildSrpResult(categoryEnum)];

      return {
        category: categoryEnum,
        suggestions,
      };
    })
    // if no results in any category, show SRP links; otherwise,
    // remove categories with no suggestions, but not Zola features
    .filter((cat) =>
      noResultsAnyCategory ? cat : cat.suggestions.length > 1 || cat.category === 'FEATURE'
    );

  let NUMBER_OF_CATS_WITH_SUGGESTIONS = sortedCategorySuggestions.length;
  let ROWS_REMAINING_TO_FILL = TOTAL_SUGGESTION_LIMIT;

  const buildPrimaryMarketplaceSuggestions = (suggestions: UniversalSearchSuggestionView[]) => {
    /**
     * When Marketplace is primary category, it gets split into 2 sub-categories,
     * STOREFRONTS
     * and
     * LOCATIONS (the other 3 types: US_CITY | VENDOR_CATEGORY | VENDOR_CITY_CATEGORY)
     *
     * Limit STOREFRONTS to only 3 rows (2 suggestions + 1 SRP) unless there are fewer than 8 LOCATIONS (7 suggestions + 1 SRP)
     */
    const storefronts = suggestions.filter((s) => s.type === 'STOREFRONT' || s.isSRP);
    const nonStorefronts = suggestions.filter((s) => s.type !== 'STOREFRONT' && !s.isSRP);
    const locations = [
      ...insertInArrayIf(nonStorefronts.length > 0, buildSrpResult('LOCATIONS')),
      ...nonStorefronts,
    ];
    const STOREFRONT_LIMIT = Math.max(
      3,
      PRIMARY_CATEGORY_SUGGESTION_LMIT - NUMBER_OF_CATS_WITH_SUGGESTIONS - locations.length
    );
    return [
      ...locations.slice(
        0,
        Math.min(
          locations.length,
          ROWS_REMAINING_TO_FILL - NUMBER_OF_CATS_WITH_SUGGESTIONS - STOREFRONT_LIMIT,
          PRIMARY_CATEGORY_SUGGESTION_LMIT - NUMBER_OF_CATS_WITH_SUGGESTIONS - STOREFRONT_LIMIT
        )
      ),
      ...storefronts.slice(0, STOREFRONT_LIMIT),
    ];
  };

  /**
   * Limit results to 14 rows
   * 0-1 Zola features
   * 1-8 Primary category (from `sortingPrioirty`)
   * 1-3 Secondary category
   * 0-1 from non-prioritized categories (typically only SRP links)
   */
  return sortedCategorySuggestions
    .reduce((accumulatedCategorySuggestions, currentCategory, currentIndex) => {
      const { category: name } = currentCategory;
      let { suggestions } = currentCategory;
      const suggestionsLength = suggestions.length;
      const prevCategory = accumulatedCategorySuggestions.slice(-1)[0];
      const isZolaFeatureCat = name === 'FEATURE';
      const isPrimaryCat =
        (currentIndex === 0 && !isZolaFeatureCat) ||
        (currentIndex === 1 && prevCategory?.category === 'FEATURE');

      if (isZolaFeatureCat) {
        NUMBER_OF_CATS_WITH_SUGGESTIONS -= 1;
        suggestions = suggestions.slice(0, Math.min(suggestionsLength, FEATURE_SUGGESTION_LIMIT));
      } else if (isPrimaryCat) {
        NUMBER_OF_CATS_WITH_SUGGESTIONS -= 1;
        if (name === 'MARKETPLACE') {
          suggestions = buildPrimaryMarketplaceSuggestions(suggestions);
        } else {
          suggestions = suggestions.slice(
            0,
            Math.min(
              suggestionsLength,
              ROWS_REMAINING_TO_FILL - NUMBER_OF_CATS_WITH_SUGGESTIONS,
              PRIMARY_CATEGORY_SUGGESTION_LMIT - NUMBER_OF_CATS_WITH_SUGGESTIONS
            )
          );
        }
      } else if (ROWS_REMAINING_TO_FILL <= TOTAL_SUGGESTION_LIMIT) {
        NUMBER_OF_CATS_WITH_SUGGESTIONS -= 1;
        suggestions = suggestions.slice(
          0,
          Math.min(suggestionsLength, ROWS_REMAINING_TO_FILL - NUMBER_OF_CATS_WITH_SUGGESTIONS)
        );
      } else {
        suggestions = suggestions.slice(0, 1);
      }
      ROWS_REMAINING_TO_FILL -= suggestions.length;

      return [...accumulatedCategorySuggestions, { category: name, suggestions }];
    }, [] as UniversalSearchCategorySuggestionsView[])
    .map((suggestion, index) => ({ ...suggestion, index }));
};

export default mapCategoriesToSuggestionResults;
