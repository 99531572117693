import ApiService from '@zola-helpers/client/dist/es/http/api';
import type {
  ResultViewShopSearchSuggestion,
  ShopSuggestRequest,
  WBabyRegistrySearchHitView,
  WeddingSearchRequest,
  WShopSearchResultResponse,
  WWeddingSearchResponse,
} from '@zola/svc-web-api-ts-client';
import type { SearchItem } from '../../../types';

export function searchProducts(
  shopSuggestRequest: ShopSuggestRequest
): Promise<WShopSearchResultResponse> {
  return ApiService.post(`/website-nav/web-api/v1/search/products`, shopSuggestRequest);
}

export function getProductsSuggestions(
  shopSuggestRequest: ShopSuggestRequest
): Promise<ResultViewShopSearchSuggestion> {
  return ApiService.post(`/website-nav/web-api/v1/search/productsSuggestions`, shopSuggestRequest);
}

export function searchCouples(
  weddingSearchRequest: WeddingSearchRequest
): Promise<WWeddingSearchResponse> {
  return ApiService.post(`/website-nav/web-api/v1/search/couples`, weddingSearchRequest);
}

export function searchVendors(q: string, offset: number, limit: number): Promise<SearchItem[]> {
  return ApiService.get(
    `/website-nav/web-api/v1/search/vendors?q=${q}&offset=${offset}&limit=${limit}`
  );
}

export const getBabyRegistrySuggestions = (
  query: string
): Promise<WBabyRegistrySearchHitView[]> => {
  return ApiService.post(`${window.location.origin}/api/baby/v1/search/registry`, {
    query,
  });
};
