import React, { forwardRef } from 'react';
import { getVendorWordSingular } from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import capitalizeFirst from '@zola-helpers/client/dist/es/transformers/capitalizeFirst';

import { ArrowNarrowRightIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ArrowNarrowRight';

import type {
  UniversalSearchCategorySuggestionsView,
  UniversalSearchSuggestionCategories,
  UniversalSearchSuggestionView,
} from '../types';

import {
  Container,
  Section,
  SectionTitle,
  SectionItems,
  SearchItem,
  SearchLink,
  SearchText,
  HighlightedCta,
  PressEnter,
  ItemType,
  Icon,
  HighlightedSearchTerm,
} from './UniversalSearchDropdown.styles';

export type UniversalSearchDropdownProps = {
  width: number;
  suggestionsToRender: UniversalSearchCategorySuggestionsView[];
  currentSelection: UniversalSearchSuggestionView | undefined;
  onSearchResultClick: (
    e: React.MouseEvent<HTMLAnchorElement>,
    item: UniversalSearchSuggestionView,
    category: UniversalSearchSuggestionCategories,
    groupPosition: number
  ) => void;
};

const ASTERISK_FLANKED_REGEX = /(\*.*?\*)/;

const UniversalSearchDropdown = forwardRef(function UniversalSearchDropdown(
  {
    width,
    suggestionsToRender,
    currentSelection,
    onSearchResultClick,
  }: UniversalSearchDropdownProps,
  ref: React.ForwardedRef<HTMLDivElement | null>
) {
  const replaceAsterisk = (term: string) => {
    const parts = term.split(ASTERISK_FLANKED_REGEX);

    return parts.map((part): string | JSX.Element =>
      part.match(ASTERISK_FLANKED_REGEX) ? (
        <HighlightedSearchTerm key={part}>{part.replaceAll('*', '')}</HighlightedSearchTerm>
      ) : (
        part
      )
    );
  };

  const renderItemSecondaryText = (item: UniversalSearchSuggestionView) => {
    let secondaryText = item.secondary_text || '';

    if (!secondaryText) {
      switch (item.type) {
        case 'STOREFRONT':
          secondaryText = getVendorWordSingular(item.sub_type);
          break;
        case 'US_CITY':
          secondaryText = 'Location';
          break;
        case 'VENDOR_CATEGORY':
        case 'VENDOR_CITY_CATEGORY':
          secondaryText = '';
          break;
        default:
          secondaryText = item.type;
      }
    }

    if (!secondaryText) {
      return null;
    }

    return <ItemType>{capitalizeFirst(secondaryText)}</ItemType>;
  };

  if (!suggestionsToRender.length) return null;

  return (
    <Container data-testid="UniversalSearchDropdown" width={width} ref={ref} tabIndex={0}>
      {suggestionsToRender.map(({ suggestions, title, category }) => (
        <Section key={category}>
          {title && <SectionTitle>{title}</SectionTitle>}
          <SectionItems>
            {suggestions.map((item, i) => {
              const isHighlighted = currentSelection?.id === item.id;
              const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) =>
                onSearchResultClick(e, item, category, i + 1);
              return (
                <SearchItem
                  isHighlighted={isHighlighted}
                  key={`search-item-${item.destination_url}-${i}`}
                >
                  <SearchLink
                    href={item.destination_url}
                    onClick={handleClick}
                    isSRP={!!item.isSRP}
                  >
                    <Icon>{item.icon}</Icon>
                    <SearchText>
                      {replaceAsterisk(item.term)} {renderItemSecondaryText(item)}
                    </SearchText>
                    {isHighlighted && (
                      <HighlightedCta>
                        Press <PressEnter>Enter</PressEnter>
                        <ArrowNarrowRightIcon height={20} width={20} />
                      </HighlightedCta>
                    )}
                  </SearchLink>
                </SearchItem>
              );
            })}
          </SectionItems>
        </Section>
      ))}
    </Container>
  );
});

export default UniversalSearchDropdown;
