import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

import { COLORS3, FONT, MEDIA_QUERY, SPACING, ZINDEX } from '@zola/zola-ui/src/styles/emotion';

import { SearchIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Search';

export const ANIMATION_DURATION = '300ms';

export const delayedZIndex = keyframes`
  0% { z-index: ${ZINDEX.top} }
  66% { z-index: ${ZINDEX.top} }
  100% { z-index: ${ZINDEX.bottom} }
`;

const delayedFadeIn = keyframes`
  0% { opacity: 0 }
  66% { opacity: 0 }
  100% { opacity: 1 }
`;

export const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

export const fadeOut = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 }
`;

export const Container = styled('form', {
  shouldForwardProp: (prop) => !['fixedWidth', 'isActive'].includes(prop),
})<{ fixedWidth?: boolean; isActive: boolean }>`
  position: relative;
  ${({ isActive }) =>
    isActive
      ? `z-index: ${ZINDEX.top};`
      : css`
          animation: ${delayedZIndex} ${ANIMATION_DURATION} linear;
        `}
  ${({ fixedWidth }) =>
    !fixedWidth &&
    `
    ${MEDIA_QUERY.DESKTOP} {
      width: 524px;
      transform: translateX(200px);
      margin-left: -200px;
    }
  `}
`;

export const SearchInput = styled.input<{ isActive: boolean; width: number }>`
  width: 100%;
  height: 44px;
  padding: ${SPACING.SM} ${SPACING.S16} ${SPACING.SM} 44px; // accomodate icon
  border-radius: 100px;
  color: var(--text-primary, ${COLORS3.BLACK_100});
  background: var(--background-light, ${COLORS3.WHITE_100});
  position: relative;
  z-index: ${ZINDEX.middle};
  box-sizing: border-box !important; // bootstrap override 😭
  font-weight: ${FONT.WEIGHT.REGULAR};
  ${FONT.textSizeUltraCompact(FONT.SIZE.SMALLER)}

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    ${FONT.textSizeUltraCompact(FONT.SIZE.SMALL)}
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  ${({ isActive, width }) => {
    return `
      border: 1px solid ${
        isActive
          ? `var(--border-element-selected-color, ${COLORS3.BLACK_100})`
          : `var(--border-element-color, ${COLORS3.BLACK_030})`
      };

      ${MEDIA_QUERY.DESKTOP} {
        transition: width ${ANIMATION_DURATION} linear;
        width: ${width}px;
      }
    `;
  }}
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 12px;
  left: 15px;
  z-index: ${ZINDEX.top};
  color: var(--text-primary, ${COLORS3.BLACK_100});
`;

export const ClearButton = styled.button<{ offsetRight: boolean }>`
  background-color: transparent;
  padding: 0;
  border: none;
  position: absolute;
  top: 12px;
  right: ${({ offsetRight }) => (offsetRight ? 75 : 15)}px;
  z-index: ${ZINDEX.top};
  cursor: pointer;
  width: 20px;
  height: 20px;
  animation: ${delayedFadeIn} 600ms linear;
  color: var(--text-primary, ${COLORS3.BLACK_100});

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    right: 75px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    margin-bottom: ${SPACING.S16};
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  text-decoration: underline;
  margin-left: ${SPACING.S16};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  color: var(--text-primary, ${COLORS3.BLACK_100});
`;

const spinnerAnimate = keyframes`
  0% {
    transform:rotate(0);
  }
  100% {
    transform:rotate(360deg)
  }
`;

export const LoadingSpinner = styled.div`
  position: absolute;
  z-index: ${ZINDEX.top};
  width: ${SPACING.MD};
  height: ${SPACING.MD};
  top: 12px;
  left: 15px;

  &:before {
    border-radius: 50%;
    content: ' ';
    width: ${SPACING.MD};
    height: ${SPACING.MD};
    display: inline-block;
    box-sizing: border-box;
    border: solid 2px var(--background-container, ${COLORS3.BLACK_005});
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    border-radius: 50%;
    content: ' ';
    width: ${SPACING.MD};
    height: ${SPACING.MD};
    display: inline-block;
    box-sizing: border-box;
    border-top: solid 2px var(--border-element-selected-color, ${COLORS3.BLACK_100});
    border-right: solid 2px transparent;
    border-bottom: solid 2px transparent;
    border-left: solid 2px transparent;
    position: absolute;
    top: 0;
    left: 0;
    animation: ${spinnerAnimate} 1s ease-in-out infinite;
  }
`;
