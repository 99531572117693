import ApiService from '@zola-helpers/client/dist/es/http/api';
import type {
  ResultViewShopSearchSuggestion,
  ShopSuggestRequest,
  WUniversalSearchSuggestionResultsView,
} from '@zola/svc-web-api-ts-client';
import {
  UniversalSearchSuggestionCategories,
  UniversalSearchProductStore,
  UniversalSearchCategorySuggestionsView,
} from '~/components/search/types';
import { getBabyRegistrySuggestions, getProductsSuggestions } from '~/client/v1/search';

export type UniversalSearchOptions = {
  /* defaults to all on BE */
  enabled_search_categories?: UniversalSearchSuggestionCategories[];
  /* defaults to WEDDING_REGISTRY on BE */
  product_store?: UniversalSearchProductStore;
  /* defaults to `false` on BE */
  highlighted?: boolean;
};

type UniversalSearchSuggestionResultsView = Omit<
  WUniversalSearchSuggestionResultsView,
  'categories'
> & { categories: UniversalSearchCategorySuggestionsView[] };

/**
 * Fusion search suggestions
 */
export const getUniversalSearchSuggestions = (
  query: string,
  options?: UniversalSearchOptions
): Promise<UniversalSearchSuggestionResultsView> => {
  const { highlighted = false, ...restOptions } = options || {};
  const request = {
    query,
    highlighted,
    ...restOptions,
  };
  return ApiService.post(`/website-nav/web-api/v1/universalSearch/suggest`, request);
};

/**
 * Client-side helper that hits different endpoints to fetch search suggestions.
 * Fetches baby registry and baby shop suggestions when `isBaby` is true.
 * Fetches fusion search suggestions when `isBaby` is false,
 * although there are plans to eventually migrate away from fusion.
 */
export const getBabyOrWeddingSearchSuggestions = async ({
  isBaby,
  query,
}: {
  isBaby: boolean;
  query: string;
}): Promise<UniversalSearchCategorySuggestionsView[]> => {
  const [searchSuggestions, babyRegistrySuggestions = []] = await (isBaby
    ? Promise.all([
        getProductsSuggestions({
          product_store: 'BABY_REGISTRY' as unknown as ShopSuggestRequest.ProductStoreEnum,
          query,
          store: 'REGISTRY' as unknown as ShopSuggestRequest.StoreEnum,
        }),
        getBabyRegistrySuggestions(query),
      ])
    : Promise.all([getUniversalSearchSuggestions(query)]));

  return isBaby
    ? [
        {
          category: 'SHOP' as const,
          suggestions:
            (searchSuggestions as ResultViewShopSearchSuggestion).result?.items?.map((item) => {
              return {
                destination_url: `/search/gifts?q=${item.term}`,
                parentCategory: 'SHOP' as const,
                term: item.term || '',
                type: '',
              };
            }) || [],
        },
        {
          category: 'COUPLE' as const,
          suggestions: babyRegistrySuggestions
            .filter(({ owner_name, slug }) => slug && owner_name)
            .map(({ owner_name, partner_name, slug }) => ({
              destination_url: `/registry/${slug}`,
              parentCategory: 'COUPLE' as const,
              term: `${owner_name}${partner_name ? ` & ${partner_name}` : ''}`,
              type: '',
            })),
        },
      ]
    : (searchSuggestions as UniversalSearchSuggestionResultsView).categories;
};
